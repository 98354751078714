import React from 'react'

import './app.scss'
class Site extends React.Component {
  componentDidMount() {
    // BS4 jQuery
    // $('[data-toggle="tooltip"]').tooltip();

    // BS5
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]'),
    )
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new window.bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  render() {
    return <div />
  }
}

export default Site
